import React from 'react'

const About = () => {
    return (
        <div>
            My name is Kyla.
        </div>
    )
}

export default About
