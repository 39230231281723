import React from "react";

import { Avatar, Post } from "../common";


const Blog = () => {

    
  return (
    <div className="blog_container">
      {/* <Avatar avatar={avatar}/>
      <Post /> */}
    </div>
  );
};

export default Blog;
