import React from 'react'

const Contact = () => {
    return (
        <div>
           <h1> Contact Me!</h1>
        </div>
    )
}

export default Contact
